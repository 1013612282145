header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  margin-bottom: 64px;
  padding: 0 64px;
  justify-content: space-between;
  box-sizing: border-box;
  background: #fff;
  z-index: 10;
  position: relative;
}

.user-header {
  width: 100%;
  height: 40px;
  background: #000;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 64px;
}

.user-header a {
  text-decoration: none;
  color: #fff;
  margin-left: 16px;
}

.logo {
  text-decoration: none;
}

.logo span:nth-child(1) {
  color: #000;
  font-family: fut;
  font-size: 55px;
  font-weight: 900;
}

.logo span:nth-child(2) {
  color: #0090ff;
  font-family: marker;
  font-size: 26px;
}

.login-nav a {
  text-decoration: none;
  font-size: 16px;
  margin-left: 32px;
}

.login-nav svg {
  margin-left: 10px;
  transform: rotate(180deg);
}

.dropdown {
  height: auto;
  background: #fff;
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  max-height: 0;
  padding: 0;
  overflow: hidden;
}

.dropdown--open {
  max-height: inherit;
  padding: 32px 64px;
}

.mobile-dropdown {
  display: none;
}

.dropdown a {
  margin: 16px;
  width: 85px;
}

.dropdown-button--open svg {
  transform: rotate(0deg);
}

.mobile-only {
  display: none;
}

@media (max-width: 1024px) {
  header {
    flex-direction: column;
    padding: 0;
  }
  .login-nav {
    width: 100%;
    background: #000;
    margin-top: -4px;
    border-bottom: 2px solid #fff;
    display: none;
  }
  .login-nav a {
    width: 100%;
    display: block;
    border-top: 1px solid #fff;
    padding: 16px 32px;
    margin: 0;
  }
  .login-nav button {
    background: 0;
    padding: 0;
    margin: 0;
    color: #0090ff;
  }
  .user-header {
    padding: 16px 32px;
  }
  .dropdown--open {
    display: none;
  }
  .mobile-dropdown--open {
    display: block;
  }
  .mobile-only {
    display: block;
  }
  .mobile-nav {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 32px;
    left: 32px;
    cursor: pointer;
  }
  .mobile-nav div {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    margin-bottom: 5px;
    background: #000;
  }
  .login-nav--open {
    display: block;
  }
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }
}
