@font-face {
  font-family: marker;
  src: url(assets/fonts/PermanentMarker-Regular.ttf);
}

@font-face {
  font-family: fut;
  src: url(assets/fonts/TitilliumWeb-Black.ttf);
}

@font-face {
  font-family: robo;
  src: url(assets/fonts/RobotoCondensed-Regular.ttf);
}

@font-face {
  font-family: robobold;
  src: url(assets/fonts/RobotoCondensed-Bold.ttf);
}

* {
  box-sizing: border-box;
}

html,
body {
  background: #000;
  color: #fff;
}

.bg {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-image: url(assets/images/bag-policy.jpeg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: fixed;
}

input,
textarea,
select {
  font-family: robobold;
}

#root {
  width: 100%;
  height: 100%;
}

.cover,
.overlay {
  background: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  opacity: 0.8;
  z-index: 1;
}

.overlay {
  opacity: 0.9;
  display: none;
}

.overlay--open {
  display: block;
}

.popup {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: 2px solid #fff;
  padding: 32px;
  background: #000;
}

.popup .button-holder {
  margin-top: 18px;
}

.popup .button-holder button {
  margin-right: 16px;
}

.popup h2 {
  margin-top: 0;
}

.app {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: auto;
  font-family: robobold;
  height: 100%;
}

.wrapper {
  max-width: 1026px;
  margin: auto;
  min-height: calc(100% - 300px);
}

a {
  color: #0090ff;
}

h1,
h2,
h3 {
  font-family: fut;
}

h1 span,
h2 span,
h3 span {
  color: #0090ff;
}

h1 {
  font-size: 40px;
  line-height: 46px;
}

a h1 {
  color: #fff;
}

button {
  background: #0090ff;
  color: #fff;
  padding: 16px 32px;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  font-family: robobold;
  cursor: pointer;
}

button:hover {
  opacity: 0.9;
}

.button--text {
  background: none;
  border: none;
  color: #0090ff;
  padding: 0 16px;
}

.selecter {
  border: 1px solid #0090ff;
  width: 300px;
  background: #000;
  padding-right: 16px;
}

.selecter select,
.selecter span {
  display: block;
  background: #000;
  color: #fff;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  padding: 16px;
  padding-right: 0;
}

/********** Range Input Styles **********/
/*Range Reset*/
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 200px;
}

/* Removes default focus */
input[type='range']:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type='range']::-webkit-slider-runnable-track {
  background-color: #053a5f;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */

  /*custom styles*/
  background-color: #0090ff;
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
}

input[type='range']:focus::-webkit-slider-thumb {
  border: 1px solid #053a5f;
  outline: 3px solid #053a5f;
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type='range']::-moz-range-track {
  background-color: #053a5f;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type='range']::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: #0090ff;
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
}

input[type='range']:focus::-moz-range-thumb {
  border: 1px solid #053a5f;
  outline: 3px solid #053a5f;
  outline-offset: 0.125rem;
}

.flex {
  display: flex;
  position: relative;
}

.flex--apart {
  justify-content: space-between;
  align-items: center;
}

.page-box {
  max-width: 600px;
  margin: auto;
  border: 2px solid #fff;
  padding: 64px;
  padding-top: 26px;
}

.page-box p {
  margin-top: -10px;
}

.page-box .subhead {
  margin: 0;
}

.form {
  margin-top: 64px;
}

.form input,
.form-input {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  margin-top: -10px;
}

.form textarea {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  height: 200px;
}

.form button {
  margin-top: 32px;
  margin-right: 32px;
}

.form .error,
.form .helper {
  display: block;
  margin-top: 8px;
}

.error {
  color: red;
}

.success {
  color: green;
}

.helper {
  color: #ccc;
  font-size: 14px;
}

.tagline {
  margin-top: -12px;
  font-size: 20px;
  margin-bottom: 60px;
}

.tagline span {
  color: #0090ff;
}

@media (max-width: 1024px) {
  .wrapper {
    width: 100%;
    padding: 32px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0 32px;
  }
  .page-box .flex--apart {
    flex-direction: column;
    align-items: flex-start;
  }
  .page-box .subhead {
    margin-bottom: 16px;
  }
  .form {
    margin-top: 32px;
  }
  .button-holder * {
    display: block;
  }
  .button-holder *:nth-child(1) {
    margin-bottom: 16px;
  }
  h1 {
    line-height: 2rem;
  }

  .page-box {
    border: none;
    padding: 0;
  }
}
