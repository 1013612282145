/*-- goalie --*/
.formation42312 .player:nth-child(1) {
  left: var(--gkLeft);
  top: var(--gkTop);
}

/*-- defenders --*/
.formation42312 .player:nth-child(2) {
  left: var(--left4);
  top: var(--defTop);
}

.formation42312 .player:nth-child(3) {
  left: var(--midLeft4);
  top: var(--defTop);
}

.formation42312 .player:nth-child(4) {
  left: var(--midRight4);
  top: var(--defTop);
}

.formation42312 .player:nth-child(5) {
  left: var(--right4);
  top: var(--defTop);
}

/*-- midfield --*/
.formation42312 .player:nth-child(6) {
  left: var(--midLeft4);
  top: var(--cdmTop);
}

.formation42312 .player:nth-child(7) {
  left: var(--midRight4);
  top: var(--cdmTop);
}

.formation42312 .player:nth-child(8) {
  left: var(--left3);
  top: var(--cmTop);
}

.formation42312 .player:nth-child(9) {
  left: var(--right3);
  top: var(--cmTop);
}

.formation42312 .player:nth-child(10) {
  left: var(--center);
  top: var(--cfTop);
}

/*-- strikers --*/

.formation42312 .player:nth-child(11) {
  left: var(--center);
  top: var(--stTop);
}
