footer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  padding: 0 32px;
  background: #000;
}

@media (max-width: 1024px) {
  footer .login-nav {
    display: none;
  }
}
