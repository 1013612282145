.formation-view {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.formation-view.mobile-only {
  display: none;
}

.formation-view .meta-score {
  position: relative;
  cursor: pointer;
}

.stats {
  width: 100%;
  margin-left: 32px;
  margin-top: -32px;
}

.stats h1 {
  text-transform: uppercase;
}

.stats h2 {
  margin-top: -24px;
}

.stats .username {
  display: block;
  font-size: 14px;
  margin-top: -14px;
}

.stats p {
  text-transform: uppercase;
  font-size: 16px;
}

.stats p span {
  width: 40%;
  display: inline-block;
}

.username {
  font-size: 12px;
  margin-top: -24px;
}

.pitch-view {
  width: 100%;
}

.player-info {
  border: 1px solid rgb(221, 177, 19);
  margin-top: 16px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.player-info h2 {
  margin-top: 0;
}

.player-info p {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .formation-view.mobile-only {
    display: block;
  }
  .formation-view {
    flex-direction: column-reverse;
  }
  .stats {
    margin-left: 0;
  }
}
