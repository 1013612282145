.field {
  width: 100%;
  height: auto;
  padding-top: 75%;
  border: 1px solid #f9f9f9;
  position: relative;
  box-sizing: border-box;
}

.field:hover {
  border: 1px solid rgb(221, 177, 19);
}

.field--large:hover {
  border: 1px solid #fff;
}

.field-bg {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  background-image: url('../../../assets/images/png-transparent-football-field-football-field-green-background-football.png');
  background-size: 220% auto;
  background-position: center center;
}

.field-bg::after {
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: #000;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}

.player {
  position: absolute;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  transform: translateY(-50%) translatex(-50%);
}

.player:after {
  position: absolute;
  height: 20px;
  width: 20px;
  content: ' ';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='20px' height='20px' viewBox='0 0 1144.000000 1280.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cmetadata%3ECreated by potrace 1.15  written by Peter Selinger 2001-2017%3C/metadata%3E%3Cg transform='translate(0.000000 1280.000000) scale(0.100000 -0.100000)' fill='%23f9f9f9' stroke='none'%3E%3Cpath d='M812 11987 c-694 -694 -812 -816 -812 -840 0 -18 29 -75 79 -155 426 -684 718 -1516 803 -2287 20 -183 17 -736 -5 -955 -33 -326 -67 -560 -156 -1098 -141 -845 -171 -1144 -171 -1692 0 -988 160 -1539 606 -2095 123 -152 384 -417 507 -513 396 -309 950 -605 1812 -967 670 -281 833 -360 1175 -565 485 -290 825 -553 995 -768 35 -45 45 -51 77 -52 34 0 42 7 116 93 207 243 675 580 1207 869 248 135 258 140 950 433 897 380 1460 685 1830 990 102 85 280 261 396 395 370 423 562 829 648 1370 29 177 40 302 52 565 20 462 -17 849 -161 1680 -105 605 -158 968 -197 1355 -22 220 -25 773 -5 955 85 772 377 1602 803 2287 50 80 79 137 79 155 0 24 -118 146 -813 841 l-812 812 -4095 0 -4095 0 -813 -813z'/%3E%3C/g%3E%3C/svg%3E");
}

.field--large .player {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.field--large .player:after {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='30px' height='30px' viewBox='0 0 1144.000000 1280.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cmetadata%3ECreated by potrace 1.15  written by Peter Selinger 2001-2017%3C/metadata%3E%3Cg transform='translate(0.000000 1280.000000) scale(0.100000 -0.100000)' fill='%23f9f9f9' stroke='none'%3E%3Cpath d='M812 11987 c-694 -694 -812 -816 -812 -840 0 -18 29 -75 79 -155 426 -684 718 -1516 803 -2287 20 -183 17 -736 -5 -955 -33 -326 -67 -560 -156 -1098 -141 -845 -171 -1144 -171 -1692 0 -988 160 -1539 606 -2095 123 -152 384 -417 507 -513 396 -309 950 -605 1812 -967 670 -281 833 -360 1175 -565 485 -290 825 -553 995 -768 35 -45 45 -51 77 -52 34 0 42 7 116 93 207 243 675 580 1207 869 248 135 258 140 950 433 897 380 1460 685 1830 990 102 85 280 261 396 395 370 423 562 829 648 1370 29 177 40 302 52 565 20 462 -17 849 -161 1680 -105 605 -158 968 -197 1355 -22 220 -25 773 -5 955 85 772 377 1602 803 2287 50 80 79 137 79 155 0 24 -118 146 -813 841 l-812 812 -4095 0 -4095 0 -813 -813z'/%3E%3C/g%3E%3C/svg%3E");
}

.player.animate:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='30px' height='30px' viewBox='0 0 1144.000000 1280.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cmetadata%3ECreated by potrace 1.15  written by Peter Selinger 2001-2017%3C/metadata%3E%3Cg transform='translate(0.000000 1280.000000) scale(0.100000 -0.100000)' fill='%23DDB113' stroke='none'%3E%3Cpath d='M812 11987 c-694 -694 -812 -816 -812 -840 0 -18 29 -75 79 -155 426 -684 718 -1516 803 -2287 20 -183 17 -736 -5 -955 -33 -326 -67 -560 -156 -1098 -141 -845 -171 -1144 -171 -1692 0 -988 160 -1539 606 -2095 123 -152 384 -417 507 -513 396 -309 950 -605 1812 -967 670 -281 833 -360 1175 -565 485 -290 825 -553 995 -768 35 -45 45 -51 77 -52 34 0 42 7 116 93 207 243 675 580 1207 869 248 135 258 140 950 433 897 380 1460 685 1830 990 102 85 280 261 396 395 370 423 562 829 648 1370 29 177 40 302 52 565 20 462 -17 849 -161 1680 -105 605 -158 968 -197 1355 -22 220 -25 773 -5 955 85 772 377 1602 803 2287 50 80 79 137 79 155 0 24 -118 146 -813 841 l-812 812 -4095 0 -4095 0 -813 -813z'/%3E%3C/g%3E%3C/svg%3E");
}

.animate::before {
  animation: pulsePlayer 1.5s infinite;
  background-color: rgb(221, 177, 19);
  border-radius: 50%;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 2;
}

@keyframes pulsePlayer {
  0% {
    opacity: 1;
    transform: scale(0.5);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}

:root {
  --gkTop: 85%;
  --gkLeft: 50%;
  --defTop: 65%;
  --left4: 20%;
  --midLeft4: 40%;
  --midRight4: 60%;
  --right4: 80%;
  --left3: 25%;
  --right3: 75%;
  --center: 50%;
  --cdmTop: 50%;
  --cmTop: 40%;
  --cfTop: 30%;
  --stTop: 15%;
  --left5: 15%;
  --midLeft5: 32%;
  --midRight5: 67%;
  --right5: 85%;
}
