.instructions {
  width: 100%;
}

.instructions p {
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.instructions p span {
  width: 200px;
  display: inline-block;
}

.instructions p span:nth-child(3) {
  width: auto;
}

.instructions .selecter {
  display: inline-block;
}

.instructions input[type='range'] {
  width: 235px;
  height: 53px;
  margin-right: 16px;
}

.formation-selecter {
  width: 500px;
}

.create {
  margin-top: -32px;
}

.create h1 {
  margin-top: 48px;
}

.create .player-info {
  width: 100%;
}

.pitch-view {
  width: 100%;
}

.create button {
  margin-top: 48px;
}

.create .flex--50 > div:nth-child(1) {
  width: 60%;
}

.create .flex--50 > div:nth-child(2) {
  width: 40%;
}

.player-info .selecter {
  width: 50%;
  margin-bottom: 16px;
}

.create .sticky {
  position: sticky;
  top: 32px;
}

.create .form-input {
  width: 300px;
  display: inline-block;
  margin-top: 0;
}

@media (max-width: 1024px) {
  .desktop-only {
    display: none;
  }
  .create .flex--50 {
    flex-direction: column;
  }
  .create .flex--50 > div:nth-child(1),
  .create .flex--50 > div:nth-child(2) {
    width: 100%;
  }
  .formation-selecter {
    width: 100%;
  }
}
