.formation {
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: #fff;
  cursor: pointer;
  position: relative;
  margin-bottom: 32px;
}

.formation > a {
  text-decoration: none;
  display: block;
}

.formation .flex > div:nth-child(1) {
  width: calc(100% - 35px);
}

.formation h3 {
  margin-bottom: -20px;
  margin-top: 16px;
  font-size: 28px;
  font-weight: 900;
  width: 100%;
}

.formation h4 {
  margin-top: 20px;
  font-size: 22px;
  margin-bottom: -16px;
}

.formation h5 {
  margin-top: 18px;
}

.formation .error {
  font-size: 14px;
}

.label,
.game-tag {
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  display: inline-block;
  padding: 2px 4px;
  border-radius: 3px;
  margin-right: 8px;
}

.meta-score {
  position: absolute;
  right: 0;
  top: 16px;
  width: 35px;
  height: 35px;
}

.meta-score span {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #000;
}

.snapshot-label {
  display: block;
  color: rgb(221, 177, 19);
  margin-bottom: 4px;
  font-size: 14px;
}

.snapshot {
  border: 1px solid rgb(221, 177, 19);
  padding: 16px;
  margin-bottom: 8px;
}

.snapshot p {
  padding: 0;
  margin: 0;
  padding: 2px 0;
}

.snapshot p span {
  width: 125px;
  display: inline-block;
}

.label {
  background: rgb(221, 177, 19);
}

.game-tag {
  background: rgb(89, 27, 235);
}

.likes {
  color: rgb(221, 177, 19);
  text-transform: none;
  font-size: 14px;
  margin-top: 8px;
  display: block;
}

.date {
  font-size: 14px;
  margin-top: 8px;
}

@media (max-width: 1024px) {
  .formation .meta-score {
    right: 10px;
    top: 10px;
  }

  .formation .flex {
    position: static;
  }
}
