.formations {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.formations > div {
  margin-right: 16px;
  max-width: 330px;
  width: calc(33.3% - 16px);
}

.formation-holder {
  margin-top: 32px;
}

.formation-holder h2 {
  font-size: 32px;
}

@media (max-width: 768px) {
  .formations > div {
    width: 100%;
    max-width: inherit;
    margin-right: 0;
  }
}
